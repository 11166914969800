defineDs('DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/LeiaUtils', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'Shared/Framework/Mithril/Scripts/Templates/OverlayContainer',
  'DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/LeiaDataStore',
  'DanskeSpil/Domain/GameVendorLEIA/Scripts/Templates/PredepositOverlay',
  'Shared/Framework/Mithril/Scripts/Helpers/DOMUtils',
  'Common/Framework/EventHandling/Scripts/AppEvent',
], function (Utils, OverlayContainer, LeiaDataStore, PredepositOverlay, DOMUtils, AppEvent) {

  var gotoDeposit = function () {
    var currentUrl = location.href + (location.href.indexOf('skipRules') === -1 ? (location.href.indexOf('?') === -1 ? '?' : '&') + 'skipRules=1' : '');
    var url = DS.Config.PRETTYPREFIX + '/indbetaling?returnurl=' + encodeURIComponent(currentUrl);
    window.location.href = url;
  };

  var openLogin = function () {
    var promise = new Promise(function () {
      DSAPI.Account.openLogin();
    });

    return promise;
  };

  var relaySSO = function (options) {
    var promise = new Promise(function (resolve, reject) {
      DSAPI.Account.relaySSO({
        callback: function (assertionId) {
          if (assertionId) {
            if (!options.state) {
              console.error('No state given');
            }

            var sessionToken = {
              provider: DS.Config.LEIA_LOTTERY_CODE,
              value: assertionId,
              state: options.state
            };

            resolve(sessionToken);
          } else {
            reject();
          }
        }
      });
    });

    return promise;
  };

  var gotoExitGame = function () {
    appEventEmitter('close-game');
    window.top.location.href = '/quick';
  };

  var openPopup = function (url) {
    window.open(url, '_blank');
  };

  var gotoGlobalRules = function () {
    var url = '/regler--a--vilkaar/regler/spilleregler_dlo#quick';
    openPopup(url);
  };

  var gotoGameRules = function () {
    if (!LeiaDataStore.gameData) {
      console.error('No game data in store');
      return;
    }

    var url = LeiaDataStore.gameData.cmsData.helpUrl;
    if (!url) {
      console.error('Gaming rules missing on this game');
      return;
    }

    openPopup(url);
  };

  var calculateTopAreaHeight = function () {
    var $header = document.querySelector('.top-navigation');

    if (!$header || !DOMUtils.isElementDisplayed($header)) {
      return 0;
    }

    return $header.offsetHeight;
  };

  var isFullscreen = function () {
    var fullscreenElement = document.fullscreenElement || // eslint-disable-line compat/compat
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement ||
      document.fullscreen; // eslint-disable-line compat/compat

    if (fullscreenElement || document.webkitIsFullScreen) {
      return true;
    }

    return false;

  };

  var applyFullscreen = function () {
    var elem = document.body;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  var setGameContainerSize = function (data) {
    const minGameDimension = 320;
    let scaleDownFactor = 0;
    if (data && data.width) {
      LeiaDataStore.latestGameDimensions = data;
    }

    var gameDom = document.getElementById('leia-game');
    const gameIframe = gameDom?.querySelector('iframe');
    var containerDom = document.getElementById('leia-game-inner-wrapper');

    if (gameDom) {
      var width = 1030;
      var height;
      var pageWidth = document.body.clientWidth || window.innerWidth;

      var heightDecrease = calculateTopAreaHeight();
      console.debug('heightDecrease', heightDecrease);
      console.debug('window.innerHeight', window.innerHeight);
      console.debug('document.documentElement.clientHeight', document.documentElement.clientHeight);

      var isGameLandscape = LeiaDataStore.latestGameDimensions && LeiaDataStore.latestGameDimensions.width && (LeiaDataStore.latestGameDimensions.width > LeiaDataStore.latestGameDimensions.height);


      if (Utils.isMobile() || Utils.isTabletDevice()) {
        // Mobile/Tablet:
        width = pageWidth;
        height = document.documentElement.clientHeight - heightDecrease;
        if (width < minGameDimension || height < minGameDimension) {
          scaleDownFactor = 1 - ((minGameDimension - Math.min(width, height)) / minGameDimension);
        }
      } else {

        height = window.innerHeight - heightDecrease - 40;
        // But Always minimum 400px height
        height = Math.max(height, 400);

        if (LeiaDataStore.latestGameDimensions) {
          var maxWidth = 1030;
          width = Math.min(maxWidth, pageWidth, height * (LeiaDataStore.latestGameDimensions.width / LeiaDataStore.latestGameDimensions.height));
        }

        // Always limit height when landscape game:
        if (isGameLandscape && height > width) {
          height = width;
        }
      }

      if (gameIframe) {
        if (scaleDownFactor) {
          let iframeWidth = 0;
          let iframeHeight = 0;
          if (isGameLandscape) {
            iframeWidth = Math.max((width / scaleDownFactor), minGameDimension);
            iframeHeight = Math.max(height, minGameDimension);
          } else {
            iframeWidth = Math.max(width, minGameDimension);
            iframeHeight = Math.max((height / scaleDownFactor), minGameDimension);
          }
          gameIframe.style.width = `${iframeWidth}px`;
          gameIframe.style.height = `${iframeHeight}px`;
          gameIframe.style.transform = `scale(${scaleDownFactor})`;
          gameIframe.style.transformOrigin = 'top left';
        } else {
          gameIframe.style.width = '100%';
          gameIframe.style.height = '100%';
          gameIframe.style.transform = 'none';
          gameIframe.style.transformOrigin = 'none';
        }
      }

      console.debug('Setting container to w X h: ', width, height);
      gameDom.style.width = width + 'px';
      gameDom.style.height = height + 'px';
      containerDom.style.width = width + 'px';
      containerDom.style.height = height + 'px';

      if (isFullscreen()) {
        containerDom.classList.add('is-fullscreen');
      } else {
        containerDom.classList.remove('is-fullscreen');
      }
    }
  };

  var trackingProductDetails = function (gameName, stakeAmount) {
    return {
      name: 'quick', // Always
      price: stakeAmount, // total price if available on page load
      brand: 'dlo_qui', // Always
      category: 'instant', // also possible is extended, games_probability and games_mg
      variant: gameName, // use the actual game name here (usually written in the URL)
      quantity: 1 // only one product added, integer
    };
  };

  var addToDatalayer = function (data) {
    console.debug('Add to datalayer', data);

    if (window.dataLayer) {
      window.dataLayer.push(data);
    }
  };


  var showPreDepositOverlay = function () {

    var promise = new Promise(function (resolve, reject) {
      new OverlayContainer(PredepositOverlay()).show(resolve, reject);
    });

    return promise;

  };

  var removeLoadingClass = function () {
    var containerDom = document.getElementById('leia-game-inner-wrapper');
    if (containerDom) {
      containerDom.classList.remove('loading');
    }
  };

  var setTopNavigationDisplayOnMobileLandScape = function (shouldDisplay) {
    document.body.classList.toggle('hide-top-navigation-on-mobile-landscape', !shouldDisplay);
  };

  const appEventEmitter = (eventType, eventData) => {
    if (!window.dsApplicationConfig || window.dsApplicationConfig.ApplicationId !== 'ISOBAR_LOTTO_MOBILENATIVE') return;
    AppEvent.fire(eventType, eventData);
  };

  // Public functions:
  return {
    gotoDeposit,
    openLogin,
    relaySSO,
    gotoExitGame,
    openPopup,
    gotoGlobalRules,
    gotoGameRules,
    calculateTopAreaHeight,
    setGameContainerSize,
    trackingProductDetails,
    addToDatalayer,
    applyFullscreen,
    showPreDepositOverlay,
    removeLoadingClass,
    setTopNavigationDisplayOnMobileLandScape,
    appEventEmitter
  };

});
